//Angular + Material + Masonry
import { BrowserModule } from '@angular/platform-browser';
import { PlatformModule } from '@angular/cdk/platform';
import { NgModule, Injector } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatListModule } from '@angular/material/list';
import { NgxMasonryModule } from 'ngx-masonry';

//Kendo
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { MatTabsModule } from '@angular/material/tabs';

//Font awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

import {
  faAngleDoubleLeft as angleDoubleLeft,
  faAngleDoubleRight as angleDoubleRight,
  faArchive as archive,
  faBell as bell,
  faBuilding as building,
  faChair as chair,
  faChartBar as chartBar,
  faChevronCircleDown as chevronCircleDown,
  faChevronCircleUp as chevronCircleUp,
  faCity as city,
  faCloudUploadAlt as cloudUploadAlt,
  faClock as clock,
  faCog as cog,
  faDatabase as database,
  faEllipsis as ellipsis,
  faExclamationCircle as exclamationCircle,
  faFile as file,
  faFileContract as fileContract,
  faFileCsv as fileCsv,
  faFileExcel as fileExcel,
  faFileImage as fileImage,
  faFileLines as fileLines,
  faFilePdf as filePdf,
  faFilePowerpoint as filePowerpoint,
  faFileWord as fileWord,
  faFileZipper as fileZip,
  faFilter as filter,
  faFileArrowDown as fileArrowDown,
  faHistory as history,
  faHospital as hospital,
  faInfoCircle as infoCircle,
  faLayerGroup as layerGroup,
  faList as list,
  faMinusCircle as minusCircle,
  faPen as pen,
  faPeopleRoof as peopleRoof,
  faPersonBooth as personBooth,
  faPlus as plus,
  faPlusCircle as plusCircle,
  faPrint as print,
  faSearch as search,
  faSignOutAlt as signOutAlt,
  faStar as star,
  faTableCells as tableCells,
  faTag as tag,
  faToolbox as toolbox,
  faTrash as trash,
  faTv as tv,
  faUserCog as userCog,
  faUserLock as userLock,
  faEnvelope as envelope,
  faClose as close
} from '@fortawesome/free-solid-svg-icons';

//msal imports
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';

// PowerBI imports
import { PowerBIEmbedModule } from 'powerbi-client-angular';

//local imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabContentLoadOnDemandDirective } from './_helpers/lazyload.directive';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NewSpaceConfiguratorComponent } from './newspace-configurator/newspace-configurator.component';
import { UnityModule  } from './unity/unity.module'; 
import { HomeComponent } from './home/home.component';
import { FileSizePipe } from './_helpers/filesize.pipe';
import { NavigationComponent } from './main-layout/navigation/navigation.component';
import { FooterComponent } from './main-layout/footer/footer.component';
import { ReportsComponent } from './reports/reports.component';
import { SafePipe } from './_helpers/safePipe';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { YesNoDialogComponent } from './yes-no-dialog/yes-no-dialog.component';
import { Security } from './security';
import { ConfirmationDialogComponent } from './confirmation/confirmation-dialog.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UserPermissionsService } from './_services/user-permissions.service';
import { CommunicationService } from './_messaging/communication-service';

export let InjectorInstance: Injector;

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
    ErrorDialogComponent,
    FileSizePipe,
    FooterComponent,
    HomeComponent,
    NavigationComponent,
    NewSpaceConfiguratorComponent,
    PageNotFoundComponent,
    ReportsComponent,
    SafePipe,
    TabContentLoadOnDemandDirective,
    WelcomeComponent,
    YesNoDialogComponent,    
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DateInputsModule,
    DialogsModule,
    DragDropModule,
    DropDownsModule,
    FontAwesomeModule,
    FormsModule,
    GridModule,
    HttpClientModule,
    IconsModule,
    LabelModule,
    LayoutModule,
    MatDialogModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatTabsModule,
    MatSelectModule,
    MatListModule,
    MatProgressSpinnerModule,
    MsalModule,
    NgbModule,
    NgbDropdownModule,
    NgxMasonryModule,
    NotificationModule,
    PopupModule,
    PowerBIEmbedModule,
    ProgressBarModule,
    ReactiveFormsModule,
    TreeListModule,
    TreeViewModule,
    TooltipModule,
    UploadModule,
    WindowModule,
    PlatformModule,
    UnityModule
  ],
  providers: [

    UserPermissionsService,
    Security,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,     
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: (security: Security) =>
      {
        return security.MSALInstanceFactory();
      },
      deps: [Security]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (security: Security) =>
      {
        return security.MSALGuardConfigFactory();
      },
      deps: [Security]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory:  (security: Security) =>
      {
        return security.MSALInterceptorConfigFactory();
      },
      deps: [Security]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor(
    private injector: Injector,
    library: FaIconLibrary,
    private communicationService: CommunicationService
  ) {
    InjectorInstance = this.injector;
    library.addIcons(angleDoubleLeft, angleDoubleRight, archive, bell, building, chair, chartBar,
      chevronCircleDown, chevronCircleUp, city, clock, cloudUploadAlt, cog, database,
      ellipsis, exclamationCircle, file, fileArrowDown, fileContract, fileCsv, fileExcel,
      fileImage, fileLines, filePdf, filePowerpoint, fileWord, fileZip, filter, history, hospital,
      infoCircle, layerGroup, list, minusCircle, pen, peopleRoof, personBooth, plus, plusCircle,
      print, search, signOutAlt, star, tableCells, tag, toolbox, trash, tv, userCog, userLock,
      envelope, close);

    this.communicationService.initialize();
  }
}
